
import Component, { ComponentAttrs } from 'flarum/common/Component'
import { Data } from '../ProcessData'

export interface GeoIpBarAttrs extends ComponentAttrs {
  elements: Data
}

export default class GeoIpToolBar<
  CustomAttrs extends GeoIpBarAttrs = GeoIpBarAttrs
> extends Component<CustomAttrs> {
  view() {
    const { elements } = this.attrs
    return (
      <div className='userIp-container'>
        <div className='ip-locate' id='info-country'>{`${elements["code"]} | ${elements["region"]}`}</div>
        <div className='ip-locate' id='info-isp'>{`${elements["isp"]}`}</div>
      </div>
    )
  }
}
